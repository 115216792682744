<template>
  <th-page-wrapper>
    <!-- Form -->
    <page-form
      :key="$route.fullPath"
      @cancel-requested="handleClose"
      @delete-requested="handleDelete"
      @handled-item="refresh"
    />

    <!-- Form End -->

    <!-- Table -->
    <th-datatable
      ref="table"
      :headers="headers"
      do-route
      :show-operations="false"
      no-meta-check
      route-base="/accounting/accounts"
      resource="accounts"
      :resource-query="{ query: { type: 'revenue', deleted: false } }"
      :buttons="computedButtons"
      show-search-filter
      :search-filters="filtersList"
      do-route-filters
      @loading-error="handleLoadingError"
    />
  </th-page-wrapper>
</template>

<script>
import th from '@tillhub/javascript-sdk'
import PageForm from './components/form'
import { h } from 'vue'

export default {
  metaInfo() {
    return {
      title: this.$t('nav.main.items.accounting.accounts')
    }
  },
  components: {
    PageForm
  },
  props: {
    resources: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      filtersList: [
        {
          name: 'account_number',
          type: 'resource-select',
          label: this.$t('pages.accounts.all.filters.account_number.label'),
          placeholder: this.$t('common.inputs.placeholders.select'),
          defaultKey: 'fa_account_number',
          appendToBody: true,
          computedFields: ['fa_account_number', 'name'],
          resourceQuery: { type: 'revenue' },
          options: {
            resource: 'accounts'
          },
          formatValue: (value) => {
            if (!this.resources.accounts) return value
            return (
              this.resources.accounts.find(
                (item) => item.fa_account_number === value
              )?.name || ' - '
            )
          }
        }
      ],
      headers: [
        {
          field: 'name',
          label: this.$t('common.headers.name.title'),
          fallback: '-',
          minWidth: 120,
          truncate: true
        },
        {
          field: 'fa_account_number',
          label: this.$t('pages.accounts.all.headers.fa_account_number'),
          minWidth: 120,
          truncate: true,
          fallback: '-'
        }
      ],
      buttons: [
        {
          type: 'create',
          scopes: ['accounting:revenue_accounts:create']
        }
      ],
      selectedItems: [],
      dependencies: {
        products: 0,
        product_groups: 0
      }
    }
  },
  computed: {
    isEdit() {
      return this.$route.name === 'accounting-accounts-edit'
    },
    isNew() {
      return this.$route.name === 'accounting-accounts-new'
    },
    computedButtons() {
      return this.buttons.filter((b) =>
        b.scopes ? this.$checkPermissions({ scopes: b.scopes }) : true
      )
    }
  },
  mounted() {
    if (this.isEdit || this.isNew) {
      this.$thModal.show('account')
    }
  },
  methods: {
    handleExport() {},
    handleSelectionChange(val) {
      this.selectedItems = val
    },
    handleClose() {
      this.$router.push({ name: 'accounting-accounts-default' })
    },
    refresh() {
      this.handleClose()
      this.$refs.table.refresh()
    },
    async handleDelete(payload) {
      try {
        await this.confirmMasterdataResourceDelete(payload)
      } catch (err) {
        // no-op
        return
      }

      this.deleteAccount(payload)
    },
    async deleteAccount(payload) {
      const successMessage = this.$t('common.success.action.delete.single', {
        resource: this.$t('common.resource.revenue_account.singular')
      })
      const errorMessage = this.$t('common.error.action.delete.single', {
        resource: this.$t('common.resource.revenue_account.singular')
      })

      try {
        const inst = th.accounts()
        await inst.delete(payload.id)
        this.$message({
          type: 'success',
          message: successMessage
        })
        this.refresh()
      } catch (err) {
        this.$logException(err, {
          message: errorMessage
        })
      }
    },
    handleLoadingError(err) {
      this.$logException(err, {
        trackError: false,
        message: this.$t('common.error.action.read.multiple', {
          resources: this.$t('pages.accounts.title')
        })
      })
    },
    async confirmMasterdataResourceDelete(payload) {
      await this.fetchDependencies(payload)

      const depsTranslations = {
        products: this.$t('pages.products.title'),
        product_groups: this.$t('pages.product_groups.title')
      }

      const affectedDependencies = Object.entries(this.dependencies).map(
        (dep) => {
          const [key, value] = dep
          const text = `${depsTranslations[key]}: ${value}`
          return h('li', null, text)
        }
      )

      await this.$confirm('', this.$t('common.delete_confirm.title'), {
        customClass: 'masterdata-confirm-box',
        confirmButtonText: this.$t(
          'common.interactions.buttons.masterdata_delete_confirm'
        ),
        cancelButtonText: this.$t('common.interactions.buttons.cancel'),
        type: 'warning',
        message: h('div', {}, [
          h('p', {}, this.$t('common.masterdata_delete_confirm.content')),
          h(
            'p',
            {
              class: 'confirm-message-affected-deps',
              style: 'margin-top: 1rem;'
            },
            [
              h(
                'span',
                {},
                `${this.$t('common.masterdata_delete_confirm.dependencies')}:`
              ),
              h('ul', { style: 'margin: 0.2rem 0;' }, affectedDependencies)
            ]
          )
        ])
      })
    },
    async fetchDependencies(payload) {
      try {
        this.loading = true
        const { data } = await th
          .dependencies()
          .get({ type: 'revenue_account', resource: payload.id })
        this.dependencies = { ...this.dependencies, ...data[0] }
      } catch (err) {
        this.$logException(err, { trackError: false })
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped></style>
