<template>
  <th-modal
    width="500px"
    name="account"
    :title="$t('common.resource.revenue_account.singular')"
    @close="$emit('cancel-requested')"
  >
    <el-form ref="form" v-loading="loading" :model="form" :rules="rules">
      <!-- Name -->
      <el-form-item
        :label="$t('pages.accounts.edit.form.properties.name.label')"
        prop="name"
      >
        <el-input v-model="form.name" />
      </el-form-item>

      <!-- Account number -->
      <el-form-item
        :label="
          $t('pages.accounts.edit.form.properties.fa_account_number.label')
        "
        prop="fa_account_number"
      >
        <el-input
          v-model="form.fa_account_number"
          :disabled="!isNew && isDatevEnabled && isDatevStrictMode"
        />
      </el-form-item>
    </el-form>

    <!-- Actions -->
    <template #footer>
      <actions
        class="pr-0 pl-0 w-full"
        :is-new="isNew"
        permission-prefix="accounting:revenue_accounts"
        @save="submitForm('form')"
        @delete="$emit('delete-requested', payload)"
      />
    </template>
  </th-modal>
</template>

<script>
import th from '@tillhub/javascript-sdk'
import safeGet from 'just-safe-get'
import pick from 'just-pick'
import Actions from '@/components/actions'

function genInitialData() {
  return {
    name: null,
    fa_account_number: null
  }
}

function makeHandleableBody(payload, form) {
  return pick(
    payload,
    Object.keys(form).filter((item) => ![].includes(item))
  )
}

export default {
  components: {
    Actions
  },
  props: {
    visible: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      loading: false,
      valid: false,
      form: genInitialData(),
      rules: {
        name: [
          {
            required: true,
            message: this.$t('pages.accounts.edit.form.rules.name.required'),
            trigger: 'blur'
          },
          {
            min: 3,
            max: 128,
            message: this.$t('common.forms.rules.min_max_length', {
              min: 3,
              max: 128
            }),
            trigger: 'blur'
          }
        ],
        fa_account_number: [
          {
            required: true,
            message: this.$t(
              'pages.accounts.edit.form.rules.fa_account_number.required'
            ),
            trigger: 'blur'
          },
          {
            min: 1,
            max: 20,
            message: this.$t('common.forms.rules.min_max_length', {
              min: 1,
              max: 20
            }),
            trigger: 'blur'
          }
        ]
      },
      payload: {}
    }
  },
  computed: {
    isNew() {
      // cheat
      if (this.$route.params.id && this.$route.params.id === 'new') return true

      return !this.$route.params.id
    },
    isDatevEnabled() {
      return this.$isFeatureEnabled('datev')
    },
    isDatevStrictMode() {
      const strictMode = safeGet(
        this.$store.state.Config.clientAccountConfiguration,
        ['datev', 'strict_mode']
      )
      return typeof strictMode === 'boolean' ? strictMode : true
    }
  },
  watch: {
    visible: function (newValue, oldValue) {
      if (oldValue && !newValue) this.resetForm()
      if (!newValue) this.resetData()
      if (newValue && !this.isNew) this.fetch(this.$route.params.id)
    }
  },
  async mounted() {
    if (!this.isNew) this.fetch(this.$route.params.id)
  },
  methods: {
    async fetch(id) {
      try {
        const inst = th.accounts()

        this.loading = true

        const { data = {} } = await inst.get(id)

        if (data.id) {
          this.handleItem(data)
        }

        this.loading = false
      } catch (err) {
        this.loading = false
        this.$logException(err, {
          trackError: false,
          message: this.$t('common.error.action.read.single', {
            resource: this.$t('common.resource.account.singular')
          })
        })
      }
    },
    handleItem(item) {
      this.payload = item
      this.form = {
        ...pick(item, Object.keys(this.form))
      }
    },
    submitForm(formName) {
      this.validate('form', (valid) => {
        if (!valid) {
          return this.$message({
            type: 'warning',
            message: this.$t(
              'common.forms.rules.field_warnings.invalid_inputs.required'
            )
          })
        }

        if (this.isNew) return this.create()
        this.alter(this.payload.id)
      })
    },

    validate(formName = 'form', cb) {
      this.$refs[formName].validate((valid) => {
        return cb(valid)
      })
    },
    resetForm(formName) {
      if (!this.$refs[formName]) return
      this.$refs[formName].resetFields()
    },
    resetData(formName) {
      this.form = genInitialData()
    },
    async alter(id) {
      const payload = {
        ...this.payload,
        ...this.form,
        type: 'revenue'
      }

      const shouldWarnOnDatevChange =
        !this.isNew &&
        this.isDatevEnabled &&
        this.isDatevStrictMode === false &&
        this.payload.fa_account_number !== this.form.fa_account_number

      if (shouldWarnOnDatevChange) {
        const objectName = this.$t(
          'pages.accounts.edit.form.properties.fa_account_number.label'
        )
        try {
          await this.$confirm(
            this.$t('common.datev.related_change.warning.message', {
              objectName
            }),
            this.$t('common.titles.warning'),
            {
              confirmButtonText: this.$t('common.interactions.buttons.ok'),
              cancelButtonText: this.$t('common.interactions.buttons.cancel'),
              type: 'warning'
            }
          )
        } catch (err) {
          // no-op
          return
        }
      }

      try {
        const inst = th.accounts()
        this.loading = true

        const { data = {} } = await inst.put(
          payload.id,
          makeHandleableBody(payload, this.form)
        )

        if (data.id) {
          this.handleItem(data)
        }

        this.loading = false
        this.$emit('handled-item')
        this.$emit('altered-item')
      } catch (err) {
        this.loading = false

        this.$logException(err, {
          message: this.$t('common.forms.error.alter_fail.message', {
            resource: this.$t('common.resource.account.singular')
          })
        })
      }
    },
    async create() {
      const payload = {
        ...this.form
      }

      try {
        const inst = th.accounts()
        this.loading = true

        // we will handle currency and timezone as configuration, TODO: implement
        const { data = {} } = await inst.create({
          ...makeHandleableBody(payload, this.form),
          type: 'revenue'
        })

        if (data.id) {
          this.handleItem(data)
        }

        this.loading = false
        this.$emit('handled-item')
        this.$emit('new-item')
      } catch (err) {
        this.loading = false

        this.$logException(err, {
          message: this.$t('common.forms.error.create_fail.message', {
            resource: this.$t('common.resource.account.singular')
          })
        })
      }
    }
  }
}
</script>
